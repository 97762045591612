import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './components/layout/Layout';
import Home from './components/main/Home/Home';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import GetAQoute from './components/Pages/GetAQoute/GetAQoute';
import GetOrderSumary from './components/Pages/GetOrderSumary/GetOrderSumary';
import RefreshOnRouteChange from './RefreshOnRouteChange';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CmsPage from './components/main/Common/CmsPages/CmsPage';
import SearchChamerlean from './components/main/Common/Chamelean/SearchChamelean';
import SearchChamerleanOffers from './components/main/Common/Chamelean/SearchChameleanOffers';
import SrchChamerlean from './components/main/Common/Chamelean/SrchChamelean';

// Wireless
import QuantumMaxWireless from './components/main/forms/QuantumMaxWireless/QuantumMax';

// Cable
import QuantumMaxCable from './components/main/forms/QuantumMaxCable/QuantumMax';

// Fiber
import QuantumMaxFiber from './components/main/forms/QuantumMaxFiber/QuantumMax';

// Deal
import QuantumMaxDeal from './components/main/forms/QuantumMaxDeal/QuantumMax';

// Spectrum
import QuantumMaxSpectrum from './components/main/forms/QuantumMaxSpectrum/QuantumMax';

// Test
import QuantumMaxTest from './components/main/forms/Test/QuantumMax';

function App() {
  return (
    <div className="App">
      <Router>
          <ToastContainer />
          {/* <RefreshOnRouteChange /> */}
          <Routes>
              {/* Guest routes here */}
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/getaqoute" element={<GetAQoute />} />
                <Route path="/order-sumary" element={<GetOrderSumary />} />
                <Route path="/search" element={<SearchChamerlean />} />
                <Route path="/search-offers" element={<SearchChamerleanOffers />} />
                <Route path="/srch" element={<SrchChamerlean />} />
                <Route path="/page/:slug" element={<CmsPage />} />
              
                {/* For Quantum Max */}
                <Route path="/wireless" 
                  element=
                  {
                    <><QuantumMaxWireless /></>
                  } 
                />

                <Route path="/cabledeals" 
                  element=
                  {
                    <><QuantumMaxCable /></>
                  } 
                />

                <Route path="/fiberoffers" 
                  element=
                  {
                    <><QuantumMaxFiber /></>
                  } 
                />

                <Route path="/deals" 
                  element=
                  {
                    <><QuantumMaxDeal /></>
                  } 
                />

                <Route path="/spectrum" 
                  element=
                  { 
                    <><QuantumMaxSpectrum /></>
                  } 
                />

                <Route path="/test" 
                  element=
                  { 
                    <><QuantumMaxTest /></>
                  } 
                />
              </Route>              
          </Routes>
      </Router>
    </div>
  );
}

export default App;
