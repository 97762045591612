import React, { useState, useEffect } from "react";
import Loader from "../Loader/LoaderQuantumMax";

const SearchChameleanOffers = () => {
  const [loader, setLoader] = useState(true);
  const [provider, setProvider] = useState('all');

  useEffect(() => {
    const fetchedUrl = window.location.href;

    const params = new URL(fetchedUrl).searchParams;
    const providerName = params.get('provider');

    if(providerName)
    {
      setProvider(providerName);
    }

    const script = document.createElement("script");
    script.src = "https://static.myfinance.com/embed/inlineMedia_core.js";
    script.async = true;
    script.onload = () => {
      window.initializeInline({ loadInlineCss: true });
    };
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    const handlePageLoad = () => {
      setLoader(false);
    };

    if (document.readyState === "complete") {
      handlePageLoad();
    } else {
      window.addEventListener("load", handlePageLoad);
    }

    return () => {
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  return (
    <div className="banner_content">
        {
          loader && <Loader />
        }
        <div className="custom-container">
        {
          (provider === 'all') && (  
            <div class="myFinance-widget" data-ad-id="a1335852-e747-40fd-a1ba-4cb1b9a2b47f" data-campaign="chameleon-quantumax-zip-detected-providers" data-sub-id="PublisherSubIDHere1" data-sub-id2="PublisherSubIDHere2"></div>
        )}
        </div>
    </div>
  );
};

export default SearchChameleanOffers;
