import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import "./chatbutton.css";

const ChatButton = () => {
  const URL = process.env.REACT_APP_CHAT_URL;
  return (
    <a href={URL} target="_blank" className="chat-button">
      <FontAwesomeIcon icon={faComments} size="lg" /> 
    </a>
  );
  
};

export default ChatButton;
